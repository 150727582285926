import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

function SelectStatusModal({ downloadAllCsv }) {
  const [selectedStatus, setSelectedStatus] = useState("");

  function closeSelectStatusModal() {
    setSelectedStatus("");
    document.getElementById("selectCSVStatusModal").style.display = "none";
  }

  return (
    <div id="selectCSVStatusModal" class="modal">
      <div class="modal-add-seller">
        <div className="row">
          <div className="col-12  page_heading">
            <h4 className="  text-center pt-2">Select Status</h4>
            <span class="close pr-2  " onClick={closeSelectStatusModal}>
              &times;
            </span>
          </div>
        </div>

        <div class="modal-body">
          <div className="row mt-2">
            <div className="col-2 text-left p-0">
              <label className="input_label m-0 ">Status :</label>
            </div>
            <div className="col-10  p-0">
              <select
                className="input_styling"
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e.target.value);
                }}
              >
                <option value="">Select Status</option>
                <option value="ALL">ALL</option>
                <option value="TRANSFERRED">TRANSFERRED</option>
                <option value="PENDING">PENDING</option>
                <option value="COMPLETED">COMPLETED</option>
                <option value="Re-Purchased">Re-Purchased</option>
                <option value="CANCELLED">CANCELLED</option>
              </select>
              <span className="estaric">*</span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            className="print_btn ml-2"
            id="creditorsave"
            onClick={() => {
              if (selectedStatus) {
                closeSelectStatusModal();
                downloadAllCsv(selectedStatus);
              } else {
                toast.error("Please select a status");
              }
            }}
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
}

export default SelectStatusModal;
SelectStatusModal.propTypes = {
  downloadAllCsv: PropTypes.func,
};
